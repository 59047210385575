








import {defineComponent, PropType} from '@vue/composition-api';
import TaskState from '@/task-states/models/TaskState';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'LinkUiComponent',
  components: {StembleLatex},
  props: {
    text: {
      type: String as PropType<string>,
      default: '',
    },
    href: {
      type: String as PropType<string>,
      default: '',
    },
    taskState: {
      type: Object as PropType<TaskState | null>,
      default: null,
    },
  },
  computed: {
    hrefWithHttp(): string {
      return this.href.match(/^https?:\/\//) ? this.href : `https://${this.href}`;
    },
  },
});
